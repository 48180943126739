


export default function Profile() {


    return (
        <div className="vh-100"
            style = {{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
                marginTop: '100px'
            
            }}
        >
            <h1>User Profile Page</h1>
        </div>
    );


}